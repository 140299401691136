import { Box, Card, IconWithTextLockup, PlanProgress } from '@deckee/deck-hand';
import styled, { useTheme } from 'styled-components';
import useTripStatus from '../../hooks/use-trip-status';
import { Trip } from '../../interfaces/trip';
import StatusChip from '../status-chip';
import Deckee from '../svg/deckee';
import DiveFlag from '../svg/dive-flag';
import Globe from '../svg/globe';
import People from '../svg/people';
import PersonCircle from '../svg/person-circle';
import Ruler from '../svg/ruler';

const MEDIA_BREAK_WIDTH = '1160px';

const StyledPlanProgress = styled(PlanProgress)`
  margin-bottom: ${({ theme }) => theme.space[2]}px;
  flex: 1 0 fit-content;
`;

const StyledCardBody = styled(Card.Body)`
  @media (min-width: ${MEDIA_BREAK_WIDTH}) {
    flex-direction: row;
    align-items: center;
  }
`;

const StyledStatusChip = styled(StatusChip)`
  @media (min-width: ${MEDIA_BREAK_WIDTH}) {
    flex: 0 0 180px;
    margin-left: ${({ theme }) => theme.space[4]}px;
    width: 100%;
  }
  @media (max-width: ${MEDIA_BREAK_WIDTH}) {
    width: 100%;
  }
`;

const StyledTripCard = styled(Card)`
  cursor: pointer;
`;

const StyledCardDetails = styled(Box)`
  @media (min-width: ${MEDIA_BREAK_WIDTH}) {
    flex: 0 1 450px;
  }
`;

interface TripCardProps {
  item: Trip;
  onClick: (item: Trip) => void;
}

const TripCard = ({ item, onClick }: TripCardProps) => {
  const { sarIncident, colors } = useTripStatus(item);

  const theme = useTheme();
  return (
    <StyledTripCard
      key={item.id}
      onClick={() => onClick(item)}
      border={`2px solid ${sarIncident ? colors.status : 'white'}`}
      bg={sarIncident ? colors.statusLight : 'white'}
    >
      <StyledCardBody
        display="flex"
        justifyContent="space-between"
        alignItems="flex-start"
        flexDirection="column"
      >
        <StyledCardDetails display="flex" flexDirection="column" pb="2" mr="2">
          <Box
            fontSize={2}
            fontWeight="bold"
            display="flex"
            alignItems="center"
          >
            {item?.isDiving && (
              <Box display="flex" mr="2">
                <DiveFlag size={13} />
              </Box>
            )}
            {item?.deckeeId && (
              <Box display="flex" mr="2" ml="-3px">
                <Deckee size={22} color={theme.colors.primary} />
              </Box>
            )}
            {item.watercraft?.name}
            {item.watercraft?.registration &&
              ` (${item.watercraft?.registration?.toUpperCase()})`}
          </Box>
          <Box display="flex" flexWrap="wrap" columnGap="3" rowGap="2" mt="2">
            {typeof item?.meta?.meters === 'number' && (
              <IconWithTextLockup
                compact
                heading={
                  <Box fontSize="0">
                    {(item?.meta?.meters / 1852)?.toFixed(0)} NM away
                  </Box>
                }
                image={<Ruler size={16} />}
              />
            )}
            <IconWithTextLockup
              compact
              heading={<Box fontSize="0">{item?.owner?.fullName}</Box>}
              image={<PersonCircle size={16} color={theme.colors.secondary} />}
            />
            <IconWithTextLockup
              compact
              heading={<Box fontSize="0">{`${item?.numberOnBoard} POB`}</Box>}
              image={<People color={theme.colors.secondary} size={16} />}
            />
            {item?.endBase?.information_group?.name && (
              <IconWithTextLockup
                compact
                heading={
                  <Box fontSize="0">
                    {item?.endBase?.information_group?.name}
                  </Box>
                }
                image={<Globe size={16} />}
              />
            )}
          </Box>
        </StyledCardDetails>

        <StyledPlanProgress
          color={colors.status}
          timeTypeEnd={item?.timeType === 'return' ? 'Return' : 'Arrival'}
          timeTypeStart="Logged on"
          start={item.startTime}
          end={item.endTime}
          floatPlan={item}
        />

        <StyledStatusChip trip={item} />
      </StyledCardBody>
    </StyledTripCard>
  );
};

export default TripCard;
